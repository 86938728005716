<template>
  <div class="decorationRecordList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      exportMethod="new"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot> </template>
      <template #searchSlot>
        <v-select2
          label="所属公司"
          :width="width"
          v-model="searchParams.regionId"
          v-bind="tenantParams"
          @onChange="onChange"
        ></v-select2>
        <v-select2
          label="所属项目"
          v-model="searchParams.communityId"
          v-bind="communityParams"
        ></v-select2>

        <v-input label="苑" v-model="searchParams.blockName"></v-input>
        <v-input label="幢" v-model="searchParams.buildingNumber"></v-input>
        <v-input label="单元" v-model="searchParams.unit"></v-input>
        <v-input label="室" v-model="searchParams.room"></v-input>
        <!-- <v-select
          label="装修阶段"
          :disabled="
            !(searchParams.regionId && searchParams.regionId.length > 0)
          "
          v-model="searchParams.stageId"
          :options="decorationStageOps"
        ></v-select> -->
        <v-select
          label="是否有损坏"
          v-model="searchParams.isBroken"
          :options="statusOps"
        ></v-select>

        <v-select
          label="修复方式"
          v-model="searchParams.fixWay"
          :options="fixWayModeOps"
        ></v-select>
        <v-select
          label="退款状态"
          v-model="searchParams.returnStatus"
          :options="returnStatusMap"
        ></v-select>
        <!-- <v-input
          label="查验结果"
          :width="width"
          v-model="searchParams.userName"
        ></v-input> -->
      </template>
      <template #operateSlot="scope">
        <v-button
          permission="examine"
          v-show="scope.row.returnStatus === 1"
          text="审核"
          type="text"
          @click="edit(scope.row)"
        ></v-button>
        <!-- 测试用按钮  可删除 -->
        <!-- <v-button
          text="审核"
          type="text"
          @click="edit(scope.row, 3)"
        ></v-button> -->

        <v-button
          permission="changeExamine"
          v-show="scope.row.returnStatus === 3"
          text="变更审核"
          type="text"
          @click="edit(scope.row)"
        ></v-button>
      </template>
    </list>
    <el-dialog
      title="装修保证金退款审核"
      :visible.sync="settingShow"
      label-width="0"
      width="1000px"
      center
      ><div>
        <col2-detail>
          <col2-block class="block-title" :title="tableForm.roomName">
            <template #headerOperate>
              <el-button size="mini" class="roomName-btn">{{
                tableForm.stageName
              }}</el-button>
            </template>
            <col2-item
              label="申请人"
              :text="tableForm.applyUserName"
            ></col2-item>
            <col2-item label="申请人电话" :text="tableForm.applyUserPhone">
            </col2-item>
            <col2-item label="申请时间" :text="tableForm.applyTime">
            </col2-item>
          </col2-block>
          <col2-block class="block-title" title="损坏审核">
            <col2-item
              label="缴纳保证金"
              :text="tableForm.depositAmount"
            ></col2-item>
            <col2-item label="应退保证金" :text="tableForm.returnAmount">
            </col2-item>
            <col2-item label="是否有损坏" :text="tableForm.applyTime">
              <el-radio-group v-model="tableForm.isBroken" @input="radioBroken">
                <el-radio :label="0">否</el-radio>
                <el-radio :label="1">是</el-radio>
              </el-radio-group>
            </col2-item>
            <col2-item label="罚扣金额合计" :text="tableForm.fineAmount">
            </col2-item>
            <col2-item label="损坏明细">
              <el-button
                type="text"
                @click="addClick"
                v-if="tableForm.isBroken === 1"
                >新增</el-button
              >
              <el-button
                style="color: #606266"
                type="text"
                v-if="tableForm.isBroken === 0"
                >无</el-button
              >
            </col2-item>
            <col2-item label="修复方式" v-if="tableForm.isBroken === 1">
              <v-select
                v-model="tableForm.fixWay"
                :options="fixWayModeOps2"
              ></v-select>
            </col2-item>
          </col2-block>
          <col2-block v-if="tableForm.isBroken === 1">
            <el-table
              v-if="tableForm.brokenDetail.length > 0"
              border
              header-align="center"
              :data="tableForm.brokenDetail"
              max-height="250"
            >
              <el-table-column label="描述">
                <template slot-scope="scope">
                  <el-input
                    type="textarea"
                    placeholder="请输入内容"
                    v-model="scope.row.description"
                    maxlength="200"
                    show-word-limit
                  >
                  </el-input>
                </template>
              </el-table-column>
              <el-table-column label="图片">
                <template slot-scope="scope">
                  <v-uploader
                    ref="myUpload"
                    :action="uploadUrl"
                    :fileList.sync="scope.row.files"
                    :limit="10"
                    :fileWidth="60"
                  >
                  </v-uploader>
                </template>
              </el-table-column>
              <el-table-column label="罚扣金额" prop="amount" width="180">
                <template slot-scope="scope">
                  <!-- <el-form :model="scope.row" ref="refNumber">
                    <el-form-item
                      prop="amount"
                      :rules="[
                        { required: true, message: '金额不能为空' },
                        { type: 'number', message: '只能输入数字' },
                      ]"
                    > -->
                  <el-input
                    placeholder="请输入金额"
                    :value="scope.row.amount"
                    @blur="inputBlur(scope.row)"
                    @input="
                      (val) => {
                        onInput(val, scope.row);
                      }
                    "
                  >
                    <template slot="append">元</template>
                  </el-input>
                  <!-- </el-form-item>
                  </el-form> -->
                </template>
              </el-table-column>
              <el-table-column label="操作" width="100">
                <template slot-scope="scope">
                  <v-button
                    text="删除"
                    type="text"
                    @click.native.prevent="
                      delClick(scope.$index, tableForm.brokenDetail)
                    "
                  ></v-button>
                </template>
              </el-table-column>
            </el-table>
          </col2-block>
        </col2-detail>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="settingShow = false">取 消</el-button>
        <el-button type="primary" @click="examineUserOK">确 认</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
import {
  uploadUrl,
  getDecorationStageURL,
  exportListURL,
  getDepositList,
  getDepositDetail,
  putDeposit
} from './api'
import {
  returnStatusMap,
  fixWayModeOps,
  fixWayModeOps2,
  statusOps
} from './map'
import { mapHelper } from 'common/utils'
import { communityParams, tenantParams } from 'common/select2Params'
import { Col2Detail, Col2Block, Col2Item, TablePanel } from 'components/bussiness'
import { vUploader, vTextarea } from 'components/control'
import emitter from "element-ui/src/mixins/emitter";
import Big from "big.js";

export default {
  mixins: [emitter],
  name: 'MarginManagementList',
  components: {
    Col2Detail,
    Col2Block,
    Col2Item,
    TablePanel,
    vUploader,
    vTextarea
  },
  data () {
    return {
      dynamicValidateForm: {
        domains: [{
          examineItem: ''
        }],
        delivery: false
      },
      settingShow: false, // 装修设置弹窗
      width: 180,
      searchUrl: getDepositList,
      exportUrl: exportListURL,
      uploadUrl,
      returnStatusMap,
      fixWayModeOps,
      fixWayModeOps2,
      statusOps,
      decorationStageOps: [],
      communityParams,
      tenantParams,
      searchParams: {
        regionId: '', //公司id
        communityId: '', // 项目id
        blockName: '',
        buildingNumber: '',
        unit: '',
        room: '',
        stageId: undefined, // 装修阶段
        isBroken: undefined, // 是否损坏
        fixWay: undefined, // 修复方式
        returnStatus: undefined, // 退款状态
      },
      examineDetail: {}, // 保证金详情
      tableForm: {
        stageName: '', // 装修状态
        roomName: '', // 房间号
        applyUserName: '', // 申请人
        applyUserPhone: '',  //申请人手机号
        applyTime: '', // 申请时间
        depositAmount: '', // 已缴保证金
        isBroken: '', // 是否损坏
        returnAmount: '', //应退金额
        fineAmount: '',  // 罚扣金额
        brokenDetail: [], // 损坏明细
        fixWay: '', // 修复方式
      },
      oldMoney: 0,
      type: null,
      id: null,
      decorationId: null,
      headers: [
        {
          prop: 'regionName',
          label: '所属公司'
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'roomName',
          label: '装修房号'
        },
        {
          prop: 'stageName',
          label: '装修阶段'
        },
        {
          prop: 'depositAmount',
          label: '已缴保证金（元）',
        },
        {
          prop: 'isBroken',
          label: '是否有损坏',
          formatter (row) {
            return row.isBroken === 1 ? '是' : row.isBroken === 0 ? '否' : '/'
          }
        },
        {
          prop: 'fixWay',
          label: '修复方式',
          formatter (row) {
            return row.fixWay === 1 ? '保证金扣罚' : row.fixWay === 2 ? '自主修复' : '/'
          }
        },
        {
          prop: 'fineAmount',
          label: '罚扣金额'
        },
        {
          prop: 'returnAmount',
          label: '应退金额（元）'
        },
        {
          prop: 'returnStatus',
          label: '退款状态',
          formatter (row) {
            return row.returnStatus === 1 ? '待审核' : row.returnStatus === 2 ? '退款中' : row.returnStatus === 3 ? '确认金额' : row.returnStatus === 4 ? '完成退款' : '/'
          }
        },
        {
          prop: 'applyUserName',
          label: '申请人'
        },
        {
          prop: 'applyUserPhone',
          label: '申请人手机'
        },
        {
          prop: 'applyTime',
          label: '申请时间'
        }, {
          prop: 'approveUserName',
          label: '审核人'
        }, {
          prop: 'approveTime',
          label: '审核时间'
        }, {
          prop: 'confirmUserName',
          label: '确认人'
        }, {
          prop: 'confirmTime',
          label: '确认时间'
        },
        {
          prop: 'returnTime',
          label: '退款时间'
        },
      ],
    }
  },

  created () {

  },
  methods: {
    radioBroken () {
      if (this.tableForm.isBroken === 0) {
        this.tableForm.returnAmount = this.tableForm.depositAmount
        this.tableForm.fineAmount = 0
        this.tableForm.brokenDetail = []
      }
    },
    onInput (val, row) {
      console.log(/^\d*\.?\d+$/.test(val), 'val.indexOf("-")');
      // if (/\d*\.?\d+$/.test(val)) {
      //   row.amount = val
      // }
      // const val = e.target.value
      console.log(val, 'val----------------------');
      const regexp = /^\d+(\.\d{0,2})?$/
      // 通过校验 或者为空  并且不是0
      if (regexp.test(val) || val === '') {
        row.amount = val
      }
    },
    inputBlur (row) {
      // if (row.amount < 0) {
      //   this.$message({
      //     type: 'warning',
      //     message: '罚扣金额不能小于0 ',
      //     center: true
      //   })
      //   row.amount = ''
      // }
      // this.tableForm.fineAmount = 0
      // if (!Number.isInteger(row.amount)) {
      //   if (row.amount?.toString().split(".")[1]?.length > 2) {
      //     this.$message({
      //       type: 'warning',
      //       message: '金额输入限制两位小数',
      //       center: true
      //     })
      //     row.amount = Number(Number(row.amount).toFixed(2))
      //   }
      // }


      this.tableForm.brokenDetail.forEach((item) => {
        this.tableForm.fineAmount += Number(Number(item.amount).toFixed(2))
      })
      // 罚扣金额  fineAmount
      this.tableForm.fineAmount = 0
      this.tableForm.brokenDetail.forEach((item) => {
        this.tableForm.fineAmount += Number(Number(item.amount).toFixed(2))
      })
      this.tableForm.fineAmount = Number(this.tableForm.fineAmount.toFixed(2))
      // 应退金额（元） returnAmount
      this.tableForm.returnAmount = Number(Number(this.tableForm.depositAmount) - Number(this.tableForm.fineAmount)).toFixed(2)
      // 罚扣金额  fineAmount
      this.tableForm.fineAmount = this.tableForm.fineAmount.toFixed(2)
      // 应退金额（元） returnAmount
      this.tableForm.returnAmount = (Number(this.tableForm.depositAmount) - Number(this.tableForm.fineAmount)).toFixed(2)
      if (this.tableForm.returnAmount < 0) {
        this.$message({
          type: 'warning',
          message: '罚扣金额不能大于缴纳保证金，请重新操作',
          center: true
        })
        row.amount = 0
      }
    },
    addClick () {
      this.tableForm.brokenDetail.push({
        description: '',
        amount: 0,
        files: []
      })
    },
    delClick (row, data) {
      data.splice(row, 1)
      this.tableForm.fineAmount = 0
      this.tableForm.brokenDetail.forEach((item) => {
        this.tableForm.fineAmount += Number(Number(item.amount).toFixed(2))
      })
      this.tableForm.fineAmount = this.tableForm.fineAmount.toFixed(2)
      this.tableForm.returnAmount = ((this.tableForm.depositAmount) - Number(this.tableForm.fineAmount)).toFixed(2)
      console.log(this.tableForm.fineAmount, '扣款金额');
      console.log(this.tableForm.returnAmount, '应退金额');

    },
    async edit (row, index) {
      this.type = index
      this.id = row.id
      this.refId = row.refId
      let params = {
        depositId: row.id
      }
      this.settingShow = true
      const res = await this.$axios.get(getDepositDetail, { params })
      res.data.brokenDetail.map(it => {
        it.files = it.files.map(file => {
          return {
            url: file
          }
        })
      })
      this.tableForm = res.data
      this.tableForm.brokenDetail = res.data.brokenDetail
      this.oldMoney = res.data.fineAmount
      console.log(res.data.brokenDetail, 'res.data.brokenDetail---');
      // if (this.tableForm.returnAmount < 0) {
      //   this.tableForm.returnAmount = 0
      // }
    },
    onChange (val, echo) {
      if (val) {
        if (!echo) {
          this.searchParams.decorationStageId = undefined
        }
        this.getDecorationStage()
      } else {
        this.searchParams.decorationStageId = undefined
        this.decorationStageOps = []
      }
    },
    async examineUserOK () {
      if (this.tableForm.isBroken === 1 && this.tableForm.brokenDetail == []) {
        this.$message({
          type: 'warning',
          message: '已选择有损坏,请填写损坏明细!',
          center: true
        })
      } else {
        if (this.tableForm.fineAmount === 0) {
          this.type = 2
        } else {
          this.type = 3
        }
        this.tableForm.brokenDetail.map(item => {
          item.files = item.files.map(el => {
            return el.url
          })
        })
        this.settingShow = false
        let params = {
          brokenDetail: [...this.tableForm.brokenDetail],
          decorationId: this.refId,
          id: this.id,
          isBroken: this.tableForm.isBroken,
          status: this.type,
          fixWay: this.tableForm.fixWay

        }
        console.log(params.brokenDetail, 'params----------------');
        const { data, status } = await this.$axios.post(putDeposit, { ...params })
        if (status === 100) {
          this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
          this.$refs.list.searchData()
        } else {
          this.$message({
            type: 'error',
            message: '操作失败，请重试！',
            center: true
          })
        }
      }
    },

    async getDecorationStage () {
      let params = {
        type: 3,
        regionId: this.searchParams.regionId
      }
      const { data, status } = await this.$axios.get(getDecorationStageURL, { params })
      if (status === 100) {
        let list = data || []
        let tempList = list.map(item => {
          return {
            text: item.name,
            value: item.categoryId
          }
        }) || []
        const { setOps: stageOps } = mapHelper.setMap(tempList)
        this.decorationStageOps = stageOps(1)
      }
    },
    addDiff (a = 0, b = 0) {
      return new Big(a).plus(b).toNumber();
    },

  }
}
  </script>
  <style scoped lang="scss">
::v-deep .el-button--default {
  margin-right: 30px;
  width: 150px;
}
::v-deep .el-button--primary {
  width: 150px;
}
.block-title {
  font-size: 16px;
  color: #333;
  font-weight: 700;
}
.roomName-btn {
  width: 100px;
  margin-left: 30px;
  border-radius: 5px;
}
::v-deep .label {
  font-size: 14px;
}
::v-deep .text {
  font-size: 14px;
  font-weight: 400;
  color: #606266;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
::v-deep input[type="‘number’"] {
  -moz-appearance: textfield !important;
}
</style>
  