// 获取列表
// const getListURL = `${API_CONFIG.baseURL}smDecorationAction!list.action`
// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}decoration/list`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}decoration/export/info`
// 上传接口
const uploadUrl = `${API_CONFIG.uploadURL}?module=decoration`
// 修改时间
const editDateUrl = `${API_CONFIG.butlerBaseURL}decoration/code`
// 获取查验项列表
const getExamineURL = `${API_CONFIG.butlerBaseURL}decoration/items`
// 获取保证金列表
const getDepositList = `${API_CONFIG.butlerBaseURL}decoration/deposit/list`
// 获取装修阶段 APP2-1309 装修记录增加装修阶段搜索APP2-1336 前端 - 装修记录增加装修阶段搜索
const getDecorationStageURL = `${API_CONFIG.butlerBaseURL}inspect/category/second`
// 装修保证金详情
const getDepositDetail = `${API_CONFIG.butlerBaseURL}decoration/deposit/confirm/detail`
// 装修保证金审核
const putDeposit = `${API_CONFIG.butlerBaseURL}decoration/deposit/confirm`


export {
  getListURL,
  exportListURL,
  uploadUrl,
  editDateUrl,
  getExamineURL,
  getDepositList,
  getDecorationStageURL,
  getDepositDetail,
  putDeposit
}
