// import { generateMapByOpts, mapHelper } from 'common/utils'

// 是否有损坏
const statusOps = [
  {
    text: '全部',
    value: undefined
  }, {
    text: '是',
    value: 1
  }, {
    text: '否',
    value: 0
  }
]

// const statusMap = generateMapByOpts(statusOps)
// 修复方式
const fixWayModeOps = [
  {
    text: '全部',
    value: undefined
  }, {
    text: '保证金扣罚',
    value: 1
  }, {
    text: '自主修复',
    value: 2
  }
]
const fixWayModeOps2 = [
  {
    text: '无',
    value: 0
  },
  {
    text: '保证金扣罚',
    value: 1
  }, {
    text: '自主修复',
    value: 2
  }
]
// const clearModeMap = generateMapByOpts(clearModeOps)
// 审核结果
const returnStatusMap = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '待审核',
    value: 1
  },
  {
    text: '退款中',
    value: 2
  },
  {
    text: '确认金额',
    value: 3
  },
  {
    text: '完成退款',
    value: 4
  },
]


export {
  returnStatusMap,
  fixWayModeOps,
  statusOps,
  fixWayModeOps2
}
